<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.consignment_return_channel_list")
    }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]"
             type="flex"
             justify="space-between"
             align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input v-model:value="searchState.searchKey"
                       style="width: 250px;"
                       :placeholder="
              $t('warehouse.return_the_parcel') + '/' + $t('warehouse.plan_no')
            "
                       allowClear />
            </a-col>
             <a-col>
                <SearchProduct ref="refSearchProduct"></SearchProduct>
              </a-col>
            <a-col>
              <a-select v-model:value="searchState.sourceType"
                        style="width: 250px;"
                        :placeholder="$t('warehouse.return_of_the_source')"
                        allowClear>
                <a-select-option v-for="(item, index) in returnPlanSourceTypeList"
                                  :key="index"
                                  :value="item"
                                  :title="$t($enumLangkey('returnPlanSourceType', item))">{{ $t($enumLangkey('returnPlanSourceType', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="searchState.receiveWarehouseId"
                        style="width: 250px;"
                          optionFilterProp="search-key"
                      show-search
                        :placeholder="$t('warehouse.the_goods_warehouse')"
                        allowClear>
                <a-select-option v-for="(item, index) in warehouseList"
                                  :key="index"
                                :search-key="item.warehouseNo + item.warehouseName"
                                  :value="item.id"
                                  :title="`${item.warehouseNo}(${item.warehouseName})`">{{ item.warehouseNo }}({{ item.warehouseName }})</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="searchState.status"
                        style="width: 250px;"
                        :placeholder="$t('warehouse.status')"
                        allowClear>
                <a-select-option v-for="(item, index) in returnPlanStatusList"
                                  :key="index"
                                  :value="item"
                                  :title="$t($enumLangkey('returnPlanStatus', item))">{{ $t($enumLangkey('returnPlanStatus', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="searchState.waybillOperationStatus"
                        style="width: 250px;"
                        :placeholder="$t('warehouse.trajectory_status')"
                        allowClear>
                <a-select-option v-for="(item, index) in waybillOperationStatusEnumList"
                                  :key="index"
                                  :value="item"
                                  :title="$t($enumLangkey('waybillOperationStatus', item))">{{ $t($enumLangkey('waybillOperationStatus', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
                <a-range-picker style="width: 250px;" @change="handleDateChange" />
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleSearch">{{ $t("common.query") }}</a-button>
            </a-col>
            <a-col>
              <a-button type="ghost" :loading="exportLoading" @click="handleExport">{{ $t("common.export") }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <router-link :to="{ name: 'consignment_return_channel_create' }">
            <a-button type="primary" ghost >{{ $t("common.create") }}</a-button>
          </router-link>
        </a-col>
      </a-row>
      <a-table class="mt-3"
               :columns="columns"
               size="small"
               :data-source="tableData.tableList"
               :pagination="false"
               :scroll="{ x: 500, y: wrap.contentHeight - 170 }"
               :rowKey="
          (record, index) => {
            return index;
          }
        "
               :loading="tableData.loading">
        <template #serialNumber>
          <div>{{ $t("warehouse.return_the_parcel") }}</div>
          <div>{{ $t("warehouse.return_plan_no") }}</div>
        </template>
        <template #serialNumberData="{ record }">
          <div>{{ record.packageNo }}</div>
          <div>{{ record.returnPlanNo }}</div>
        </template>
        <template #sourceType="{ record }">
          {{ $t($enumLangkey('returnPlanSourceType', record.sourceType)) }}
        </template>
        <template #deliveryType="{ record }">
          {{ $t($enumLangkey('deliveryWay', record.deliveryType)) }}
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey('returnPlanStatus', record.status)) }}
          <div >
              <small v-if="!record.rtsStatus||record.rtsStatus == rtsStatusEnum.noMatch
              && record.status != returnPlanStatusEnum.completed
              " 
              class="text-error">
                {{$t('warehouse.warehouse_not_received_goods')}}
              </small>
              <small v-else-if="record.rtsStatus && record.rtsStatus != rtsStatusEnum.noMatch" class="text-warning">
                {{$t('warehouse.warehouse_temporarily_stored_p0_days',[record.temporaryDays||0])}}
              </small>
          </div>
        </template>
        <template #newestTrack="{ record }">
          <span v-if="record.newestTrack">
            <div><small>{{ record.newestTrack?.trackRemark }}</small></div>
            <div><small>{{ $filters.utcToCurrentTime(record.newestTrack?.trackTime) }}</small></div>
          </span>
          <span v-else>-</span>
        </template>
        <template #waybillOperationStatus="{ record }">
          <template v-if="record.isShowTrackOperate">
            <span v-if="record.waybillOperationStatus">{{ $t($enumLangkey('waybillOperationStatus', record.waybillOperationStatus)) }}</span>
            <span v-else>-</span>
          </template>
          <small v-else style="color: #AAAAAA;">{{ $t('warehouse.untraceable') }}</small>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #updateTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button>
            <router-link :to="{ name: 'consignment_return_channel_detail', params: { id: record.channelReturnPlanId } }">
              {{ $t("common.details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :disabled="!record.isShowTrackOperate"
                             :key="1"
                             :record="record">
                  {{ $t("warehouse.check_the_track") }}
                </a-menu-item>
                <a-menu-item :disabled="record.status && record.status !== returnPlanStatusEnum.planned"
                             :key="2"
                             :record="record">
                  {{ $t("common.cancel") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

       <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("menu.consignment_return_channel_list") }}
        </template>
        <template v-slot:no>
          {{trackModal.packageNo}}/{{trackModal.returnPlanNo}}
        </template>
      </CTrackInfoModal> 

    </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="tableData.pageData"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onActivated, ref } from "vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Space,
  DatePicker,
  Table,
  Dropdown,
  Menu,
  Modal,
  message,
} from "ant-design-vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc, dateString } from "@/utils/general";
import { downloadFile } from '@/utils/downloader.js';
import { getWarehouses } from "@/api/modules/common";
import { getTrack, getPlanPaged, refundChannelReturnPlan, exportChannelReturnPlan } from "@/api/modules/consignment/returnChannel";
import {
  returnPlanStatus as returnPlanStatusEnum,
  returnPlanSourceType as returnPlanSourceTypeEnum,
  warehouseType as warehouseTypeEnum,
  waybillOperationStatus as waybillOperationStatusEnum,
  rtsStatus as rtsStatusEnum
} from '@/enum/enum.json';
import SearchProduct from "../../components/SearchProduct.vue";

export default defineComponent({
  name: "consignment_return_channel_list",
  components: {
    SearchProduct,
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CTrackInfoModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const refSearchProduct = ref();


    const state = reactive({
      returnPlanSourceTypeList: [],
      returnPlanStatusList: [],
      waybillOperationStatusEnumList: [],
      warehouseList: [],
      searchState: {
        searchKey: null,
        sourceType: null,
        productId:null,
        receiveWarehouseId: null,
        status: null,
        waybillOperationStatus: null,
        startTime: null,
        endTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      exportLoading: false,
    });

    state.returnPlanSourceTypeList = Object.values(returnPlanSourceTypeEnum)
    state.returnPlanStatusList = Object.values(returnPlanStatusEnum)
    state.waybillOperationStatusEnumList = Object.values(waybillOperationStatusEnum)

    const columns = [
      {
        width: 120,
        slots: {
          title: "serialNumber",
          customRender: "serialNumberData",
        },
      },
      {
        width: 80,
        title: vueI18n.t("warehouse.return_of_the_source"),
        slots: {
          customRender: "sourceType"
        }
      },
      {
        width: 80,
        title: vueI18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryType"
        }
      },
      {
        dataIndex: "warehouseNo",
        width: 80,
        title: vueI18n.t("warehouse.the_goods_warehouse"),
      },
      {
        dataIndex: "returnNum",
        width: 70,
        title: vueI18n.t("warehouse.returns_the_number_of"),
      },
      {
        dataIndex: "productNum",
        width: 70,
        title: vueI18n.t("warehouse.product_quantity"),
      },
      {
        dataIndex: "receiveNum",
        width: 70,
        title: vueI18n.t("warehouse.received_quantity"),
      },
      {
        dataIndex: "destroyNum",
        width: 70,
        title: vueI18n.t("warehouse.destruction_of_the_number"),
      },
      {
        width: 140,
        title: vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        },
      },
      {
        width: 150,
        title: vueI18n.t("warehouse.trajectory"),
        slots: {
          customRender: "newestTrack",
        },
      },
      {
        width: 80,
        title: vueI18n.t("warehouse.trajectory_status"),
        slots: {
          customRender: "waybillOperationStatus",
        },
      },
      {
        width: 100,
        title: vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 100,
        title: vueI18n.t("warehouse.update_time"),
        slots: {
          customRender: "updateTime",
        },
      },
      {
        width: 80,
        title: vueI18n.t("warehouse.subsequent_steps"),
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handleDateChange = (e) => {
      let beginTime = null,
        endTime = null;
      if (e.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.startTime = beginTime;
      state.searchState.endTime = endTime;
    };

    const handleExport = () => {
      state.exportLoading = true
      let url = exportChannelReturnPlan();
      const data = Object.assign({}, state.searchStateCache)
      downloadFile(url, `return_channel_${dateString()}.csv`, "POST", data).then(() => {
        message.success(vueI18n.t("common.succeed"))
      }).catch(() => { }).finally(() => {
        state.exportLoading = false
      })
    }

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getPlanPaged(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handleSearch = () => {
      state.searchState.productId = refSearchProduct.value?.selectedProductId;
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    };

    const handleCancelPlan = (record) => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return refundChannelReturnPlan({ id: record.channelReturnPlanId })
                  .then(() => {
                    message.success(vueI18n.t("common.successfully_canceled"));
                    getPageData();
                  });
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item
      switch (key) {
        case 1:
          handleShowTrackModal(record);
          break;
        case 2:
          handleCancelPlan(record);
          break;
        default:
          break;
      }
    }

    const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      list: [],
      packageNo: null,
      returnPlanNo: null,
    });

    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.planId = record.channelReturnPlanId;
      trackModal.packageNo = record.packageNo;
      trackModal.returnPlanNo = record.returnPlanNo;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrack({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    onActivated(async () => {
      try {
        let { result } = await getWarehouses({ isActive: true, warehouseType: warehouseTypeEnum.consignmentWarehouse })
        state.warehouseList = result
        handleInitSearch()
      } catch (error) {

      }
    });

    return {
      ...toRefs(state),
      returnPlanStatusEnum,
      rtsStatusEnum,
      trackModal,
      columns,
      handleDateChange,
      handleExport,
      handlePage,
      handleSearch,
      handleMenuClick,
      refSearchProduct,
    };
  },
});
</script>
